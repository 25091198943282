.leverage-modal {
  max-width: 600px !important;

  &.app-body__modal {
    .modal-body {
      padding-top: 1rem;
      margin: 1rem;
      margin-right: 0.2rem;
      overflow: auto;
    }

    .modal-content {
      background: linear-gradient(315deg, #001426 0%, #042E4F 100%);
      border-radius: 1.5rem;
      max-height: 80vh;
    }

  }

  .btn {
    // width: 100%;
    // margin-top: 24px;
    border-radius: 8px;
    padding: .625rem .75rem;
    justify-content: center;
    align-items: center;
    display: flex;
    box-sizing: border-box;

    .loader {
      margin: 8px 20px;
    }

    &.disabled {
      cursor: not-allowed;
    }

    &.btn-primary {
      background: #004671;
      transition: 0.2s;
      border: none;

      &:hover {
        background: #146C98;
        transition: 0.2s;
      }
    }

    &.btn-secondary {
      border: thin solid #004671;
      background: unset;
      transition: 0.2s;

      &:hover {
        background: #083D62;
        transition: 0.2s;
        border: thin solid transparent;
      }
    }
  }

  &__heading {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  &-meta {
    // transition: 0.2s;
    // margin: 2rem;

    &__balances {
      display: flex;
      justify-content: center;
  
      &-item {
        flex: 1;

        &__available {
          font-size: 0.8rem;
          margin-bottom: 4px;
          margin-left: 4px;
        }

        &__price {
          font-size: 0.8rem;
          margin: 4px;
        }
  
        &:first-child {
          margin-right: 1rem;
        }
      }
    }

    input {
      background: transparent;
      // color: #5FBAD6;
      color: #FFF;
      border: thin solid #004671;
      padding: 21px 8px;
      border-radius: 8px;

      &::placeholder {
        color: #228FB0;
      }

      &:focus {
        background: transparent;
        // color: #5FBAD6;
        color: #FFF;
      }

      &.form-control:disabled {
        background: transparent;
        opacity: 0.6;
      }
    }

    // input[type=number]::-webkit-inner-spin-button, 
    // input[type=number]::-webkit-outer-spin-button { 
    //   // -webkit-appearance: none; 
    //   // -moz-appearance: none;
    //   // appearance: none;
    //       // margin: 0; 
    //   cursor: pointer;
    //   background: transparent;
    //   height: auto;
    // }

    &__lev {
      margin-top: 1rem;

      &-box {
        display: flex;
        align-items: center;
        margin-top: 0.3rem;
  
        &__input {
          flex: 3;
          margin-right: 1rem;

          &-label {
            font-size: 0.8rem;
            margin: 0 0 4px 4px;
          }

          .slider-container {
            margin: 8px 16px 12px 2px;
            // display: flex;
            align-items: center;
            flex: 1;
  
            &__label {
              margin-left: 4px;
              // color: #5FBAD6;
              color: #FFF;
              font-size: 14px;
              display: flex;
              margin-right: -16px;
              margin-top: -8px;

              div {
                flex: 1
              }

              div:nth-child(1) {
                // justify-self: flex-start;
                text-align: left;
              }

              div:nth-child(2) {
                text-align: center;
              }

              div:nth-child(3) {
                // justify-self: flex-end;
                text-align: right;
              }
            }
  
            .MuiSlider-thumb.Mui-disabled {
              margin-top: -2px !important;
            }
          }
        }
  
        &__selector {
          flex: 1;
          align-self: stretch;

          .lev-selector {
            width: 100%;
            // padding: .5rem .75rem;
            padding: 7px 8px;
            box-sizing: border-box;
            // display: flex;
            // align-items: center;
          }

          &-label {
            font-size: 0.8rem;
            margin: 0 0 4px 4px;
            display: flex;
            align-self: center;
          }
        }
      }

      &__yield {
        .yield-line {
          display: flex;
          padding: 4px;

          &-name {
            flex: 1;
            text-align: left;
            justify-content: flex-start;
            color: #8ED3E5;
          }

          &-value {
            text-align: right;
            justify-content: flex-end;
            display: flex;
            align-items: center;

            img {
              margin: 0 4px;
            }
          }
        }
      }

      &__summary {
        margin: 1rem 0;

        &-heading {
          font-size: 1.5rem;
          font-weight: 600;
          margin-left: 4px;
        }

        .summary-line {
          display: flex;
          padding: 4px;

          &-name {
            flex: 1;
            text-align: left;
            justify-content: flex-start;
            color: #8ED3E5;
          }

          &-value {
            text-align: right;
            justify-content: flex-end;
            display: flex;
            align-items: center;

            img {
              margin: 0 4px;
            }
          }
        }
      }
    }
  }

  &-actions {
    // transition: all 0.3s;
    // margin: 2rem;

    .back-btn {
      display: flex;
      align-items: center;
      color: #5FBAD6;
      cursor: pointer;
      transition: color 0.1s;
      margin: 1rem 0;
   
      &:hover {
        color: #FFF;
        transition: color 0.1s;
   
        img {
          filter: saturate(0) brightness(2);
        }
      }
    }

    &__container {
      &__item {
        &:not(:last-child) {
          margin-bottom: 1rem;
        }

        &-label {
          font-size: 0.8rem;
          margin-left: 8px;
        }

        &-box {
          border-radius: 12px;
          border: thin solid #004671;
          padding: 1rem;
          display: flex;
          align-items: center;

          &__desc {
            flex: 1;
          }
        }
      }
    }
  }
}

// Mobile + Tablet - till tablet is separated out
@media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
  .leverage-modal {
    &__heading {
      font-size: 1.4rem;
    }

    &-meta {
      &__info_tip {
        font-size: 0.8rem;
      }

      &__lev {
        &__yield,
        &__summary {
          font-size: 0.8rem;
        }

        &__summary {
          &-heading {
            font-size: 1.3rem;
          }
        }
      }

      // TODO: Move to add collateral modal
      &__add-collateral-info {
        font-size: 0.8rem;
      }

      &__balances {
        flex-direction: column;

        &-item {
          margin-right: 0 !important;

          &:first-child {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}
