.app-toast {
  &__toast {
    background: #002A46 !important;
    box-shadow: 0px 0px 24px 4px rgba(0, 0, 0, 0.4) !important;

    &::before {
      width: 8px;
      height: 200px;
      position: absolute;
      background: linear-gradient(315deg, #C61FB5 -6.25%, #2CABC7 100%);
      content: '';
      top: 0;
      left: 0;

      .error & {
        background: linear-gradient(0deg, #690707 2.08%, #C90741 100%);
      }
    }
  }

  &__toast-error {
    background: #002A46 !important;
    box-shadow: 0px 0px 24px 4px rgba(0, 0, 0, 0.4) !important;

    &::before {
      width: 8px;
      height: 200px;
      position: absolute;
      background: linear-gradient(0deg, #690707 2.08%, #C90741 100%);
      content: '';
      top: 0;
      left: 0;
    }
  }

  &__body {
    padding: 24px 16px !important;
    color: #FFF;
  }

  &__progress {
    background: linear-gradient(90deg, #690707 2.08%, #C90741 100%) !important;
  }

  .Toastify__close-button--default {
    // color: #5FBAD6;
    color: #FFF;
    opacity: 1;
    margin: auto 8px;

    svg {
      path {
        d: path('M8.70711 8L13.3536 3.35355L12.6465 2.64645L8.00001 7.29289L3.35356 2.64645L2.64645 3.35355L7.2929 8L2.64645 12.6464L3.35356 13.3536L8.00001 8.70711L12.6465 13.3536L13.3536 12.6464L8.70711 8Z');
      }
    }
  }
}
