.base-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  background: rgba(255,255,255,0.1);
  border-radius: 10px;
  padding: 2px 8px;
  margin: auto;
  margin-top: 4px;
  // max-width: 6rem;
  box-sizing: border-box;
  cursor: pointer;

  img {
    margin-right: 0.3rem;
  }

  &.badge-error,
  &.badge-secondary {
    border: thin solid #2CABC7;
    margin: 0 4px;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 100;
    background: unset;
  }

  &.badge-error {
    border: thin solid #EA232F;
    color: #EA232F;
  }

  &.badge-animated-success {
    margin: 0 4px;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 100;
    background: unset;
    position: relative;

    &::after {
      animation: frame-enter 1s forwards ease-in-out reverse, gradient-animation 3s ease-in-out infinite;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 3px;
      background: linear-gradient(120deg, var(--content-success), var(--brand-80), var(--content-success));
      background-size: 300% 300%;
      clip-path: polygon(0% 100%, 1.5px 100%, 1.5px 1.5px, calc(100% - 1.5px) 1.5px, calc(100% - 1.5px) calc(100% - 1.5px), 1.5px calc(100% - 1.5px), 1.5px 100%, 100% 100%, 100% 0%, 0% 0%);
    }
  }

  &.is-new {
    position: absolute;
    top: 4px;
    right: 0;
  }
}

/* motion */
@keyframes gradient-animation {
  0% {
    background-position: 15% 0%;
  }
  50% {
    background-position: 85% 100%;
  }
  100% {
    background-position: 15% 0%;
  }
}

@keyframes frame-enter {
  0% {
    clip-path: polygon(0% 100%, 1.5px 100%, 1.5px 1.5px, calc(100% - 1.5px) 1.5px, calc(100% - 1.5px) calc(100% - 1.5px), 1.5px calc(100% - 1.5px), 1.5px 100%, 100% 100%, 100% 0%, 0% 0%);
  }
  25% {
    clip-path: polygon(0% 100%, 1.5px 100%, 1.5px 1.5px, calc(100% - 1.5px) 1.5px, calc(100% - 1.5px) calc(100% - 1.5px), calc(100% - 1.5px) calc(100% - 1.5px), calc(100% - 1.5px) 100%, 100% 100%, 100% 0%, 0% 0%);
  }
  50% {
    clip-path: polygon(0% 100%, 1.5px 100%, 1.5px 1.5px, calc(100% - 1.5px) 1.5px, calc(100% - 1.5px) 1.5px, calc(100% - 1.5px) 1.5px, calc(100% - 1.5px) 1.5px, calc(100% - 1.5px) 1.5px, 100% 0%, 0% 0%);
  }
  75% {
    -webkit-clip-path: polygon(0% 100%, 1.5px 100%, 1.5px 1.5px, 1.5px 1.5px, 1.5px 1.5px, 1.5px 1.5px, 1.5px 1.5px, 1.5px 1.5px, 1.5px 0%, 0% 0%);
  }
  100% {
    -webkit-clip-path: polygon(0% 100%, 1.5px 100%, 1.5px 100%, 1.5px 100%, 1.5px 100%, 1.5px 100%, 1.5px 100%, 1.5px 100%, 1.5px 100%, 0% 100%);
  }
}
