.low-sol-warning {
  box-sizing: border-box;
  display: flex;
  max-width: max-content;
  align-items: center;
  border-radius: 8px;
  padding: 8px;
  margin: 8px auto 0;
  text-align: center;
  color: #E5DD18;

  span {
    margin-left: 8px;
  }

  .icon-with-tooltip {
    display: flex;
    align-items: center;
  }

  &.shift-up-16 {
    margin-bottom: -16px;
  }
}

// Mobile + Tablet - till tablet is separated out
@media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
  .low-sol-warning {
    font-size: 0.8rem;
  }
}