@font-face {
  font-family: "MontserratAlternates";
  src: url("../fonts/MontserratAlternates-Medium.ttf");
}

body {
  height: 100vh;
  width: 100vw;
  /* background: linear-gradient(315deg, #00111C 0%, #093C5E 100%); */
  /* background: linear-gradient(315deg, #001422, #004472); */
  font-family: Montserrat, sans-serif;
  padding: 0;
  margin: 0;
  color: #fff;
}

.page-content-container {
  flex: 1;
  align-items: center;
  justify-content: center;
  /* display: flex; */
  flex-direction: column;
  overflow: auto;
  padding-bottom: 1rem;
}

.page-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  letter-spacing: 0.125rem;
  margin: 8rem 0 4rem;
}

h1 {
  /* font-weight: 400; */
  text-shadow: #00111c 0.125rem 0 1.25rem;
}

/* .logo-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
      } */

.solfarm-logo {
  height: 18rem;
  margin: auto;
}

.vault-button {
  background: #004671;
  color: #fff;
  border: none;
  transition: 0.3s;
  display: flex;
  border-radius: 1rem;
  padding: 1rem 1.5rem;
  letter-spacing: 0;
  cursor: pointer;
  user-select: none;
  box-shadow: 0px 0px 3rem 0.5rem rgba(0, 0, 0, 0.2);
}

.vault-button:hover {
  background: #228fb0;
  transition: 0.3s;
}

.partnership {
  /* margin-bottom: 4rem; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.solana-foundation-logo {
  height: 2rem;
}

.header {
  height: 8rem;
  font-size: 1.5rem;
  padding: 2rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 80vw;
  align-self: center;
  margin: auto;
}

.header-logo {
  font-weight: 600;
  /* flex: 1; */
  text-align: left;
  font-family: "MontserratAlternates", sans-serif;
  letter-spacing: 0.25rem;
  font-size: 2rem;
  padding: 1rem;
  height: 4rem;
  display: flex;
  align-items: center;
  margin-left: 1rem;
  box-sizing: border-box;
  cursor: pointer;
}

.header-links {
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
}

.header-link {
  margin: 1rem;
  cursor: pointer;
  user-select: none;
  font-size: 1.2rem;
}

.solfarm-small-logo {
  height: 2.5rem;
  margin-right: 1rem;
}

.page-container, .about-us-page-container {
  /* z-index: -100; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.about-us-page-container {
  position: fixed;
}

.page-container:before, .about-us-page-container:before {
  width: 100vw;
  height: 100vh;
  background-image: url("../images/CoverTrans.webp");
  /* background-image: url('./tulips.svg'); */
  opacity: 0;
  content: "";
  animation-name: blink;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  background-repeat: no-repeat;
  position: absolute;
  z-index: -100;
  background-size: cover;
}

.page-container:after, .about-us-page-container:after {
  width: 100vw;
  height: 100vh;
  /* background-image: url('./CoverTrans.webp'); */
  background-image: url("../images/tulips.svg");
  opacity: 0;
  content: "";
  animation-name: blink2;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  background-repeat: no-repeat;
  position: absolute;
  z-index: -100;
  background-size: cover;
}

a {
  color: unset;
  text-decoration: unset;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 0;
  }
}

@keyframes blink2 {
  0% {
    opacity: 0.03;
  }

  50% {
    opacity: 0.05;
  }

  100% {
    opacity: 0.03;
  }
}

@media only screen and (min-width: 120.063em) {
  .solfarm-logo {
    height: 32rem;
  }

  .header {
    height: 16rem;
    font-size: 2rem;
    padding: 2rem;
  }

  .solfarm-small-logo {
    height: 4.5rem;
    margin-right: 1.5rem;
  }

  .header-logo {
    letter-spacing: 0.25rem;
    font-size: 4rem;
    padding: 1rem;
    height: 8rem;
    margin-left: 1rem;
  }

  .vault-button {
    font-size: 2rem;
    border-radius: 1rem;
    padding: 1.5rem 2rem;
    letter-spacing: 0;
    cursor: pointer;
    box-shadow: 0px 0px 3rem 0.5rem rgba(0, 0, 0, 0.2);
  }

  h1 {
    font-size: 4rem;
  }

  .page-content {
    margin: 10rem 0 6rem;
  }

  .partnership {
    font-size: 1.5rem;
  }

  .solana-foundation-logo {
    height: 3rem;
  }


  .team-grid {
    display: grid;
    /* grid-template-rows: 1fr 1fr !important;
    grid-template-columns: 1fr 1fr 1fr !important; */
    margin: 4rem 0;
  }

  .section-two > h1 {
    font-size: 3.5rem !important;
  }

  .team-member-content {
    padding: 3rem !important;
  }

  .team-member-img {
    height: 180px !important;
    width: 180px !important;
  }

  .team-member-img > img {
    height: 180px !important;
    width: 180px !important;
  }

  .team-member-details {
    margin-left: 2.5rem !important;
  }

  .team-member-name {
    font-size: 2.5rem !important;
  }

  .team-member-position {
    margin-bottom: 1.5rem !important;
    font-size: 1.5rem !important;
  }

  .team-member-intro {
    font-size: 1.4rem !important;
  }
}

/* Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
  h1 {
    text-align: center;
    margin: 0.67em 0.5em;
    font-size: 1.5rem;
  }

  body {
    overflow-x: hidden;
  }

  .header {
    width: 100vw;
    justify-content: center;
    flex-direction: column;
    height: 13rem;
  }

  .header-logo {
    margin-left: unset;
    margin-bottom: 1rem;
  }

  .header-links {
    font-size: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: unset;
  }

  .header-link {
    margin: 0.5rem 1rem;
  }

  .page-content {
    margin: 5rem 0 4rem;
  }

  .page-container:after {
    left: -10vw;
    top: -10vh;
  }

  .solfarm-logo {
    height: 16rem;
  }

  .team-grid {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr !important;
    grid-template-columns: 1fr !important;
    margin: 4rem 0;
  }

  .team-member-content {
    flex-direction: column;
  }

  .team-member-intro {
    margin-bottom: 1rem;
  }

  .team-member-details {
    margin-left: unset !important;
    margin-top: 1rem;
  }

  .team-member-name, .team-member-position {
    text-align: center;
  }

  .section-one > div {
    margin: 2rem 0 !important;
  }
}


/* About Us */

.about-us-content-container {
  overflow: auto;
}

.section-one, .section-two {
  width: 80vw;
  margin: auto;
  margin-top: 4rem;
}

.section-one > h1, .section-two > h1 {
  text-align: center;
  font-size: 2.5rem;
  letter-spacing: 0.125rem;
}

.section-one > div {
  margin: 2rem 4rem;
  font-size: 1.2rem;
  text-align: justify;
}

.section-two {
  margin-top: 0;
}

.team-grid {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  margin: 2rem 0;
}

.team-member {
  border: thin solid #004671;
  margin: 1rem;
  border-radius: 8px;
}

.team-member-content {
  display: flex;
  padding: 2rem;
}

.team-member-img {
  background: #5FBAD6;
  border-radius: 50%;
  height: 128px;
  width: 128px;
  align-self: center;
}

.team-member-img > img {
  height: 128px;
  width: 128px;
  border-radius: 50%;
}

.team-member-details {
  margin-left: 1.5rem;
  align-self: center;
}

.team-member-name {
  font-size: 1.5rem;
  font-weight: 600;
}

.team-member-position {
  margin-bottom: 1rem;
  color: #5FBAD6;
}

.team-member-intro {
  font-size: 0.8rem;
  font-weight: 100;
  /* text-align: justify; */
}