.loader {
	background: currentcolor;
	position: relative;
	animation: loader 0.7s ease-in-out infinite;
	animation-delay: 0.4s;
	width: .2em;
	height: .5em;
	margin: 0 .5em;
	&:after,
	&:before {
		content: '';
		position: absolute;
		width: inherit;
		height: inherit;
		background: inherit;
		animation: inherit;
	}
	&:before {
		right: .5em;
		animation-delay: 0.2s;
	}
	&:after {
		left: .5em;
		animation-delay: 0.6s;
	}
}

@keyframes loader {
	0%,
	100% {
		box-shadow: 0 0 0 currentcolor, 0 0 0 currentcolor;
	}
	50% {
		box-shadow: 0 -.25em 0 currentcolor, 0 .25em 0 currentcolor;
	}
}
