.infobar {
  background-color: #1C88B4;
  width: 90vw;
  min-height: 64px;
  max-height: 96px;
  margin: 0 5vw 4px 5vw;
  display: flex;
  align-items: center;
  padding: 4px 16px;
  font-weight: 600;
  border-radius: 0.5rem;
  position: fixed;
  z-index: 2;


  @media only screen and (max-width: 1226px) {
    .infobar {
      margin-top: 1vh;
    }
  }


  &--error {
    background-color: #EA232F;
  }

  &--success {
    background-color: #1CB434;
  }

  &--warn {
    background-color: #E5DD18;
  }

  &--info {
    background-color: #1C88B4;
  }

  &__message {
    flex: 1;
  }

  .btn {
    color: #FFFFFF;
    border-color: #FFFFFF;

    &:hover {
      color: #EA232F;
      background-color: #FFFFFF;
      border-color: #EA232F;
    }
  }
}


// Mobile + Tablet - till tablet is separated out
@media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
  .infobar {
    font-size: 0.8rem;

    button {
      font-size: 0.8rem;
    }
  }
}