.menu-icon-container {
  padding: 16px !important;

  .menu-icon {
    transform: translateX(0);
    width: 16px;
    height: 16px;
    cursor: pointer;
    padding: 0;
    margin: 0;
  
    li {
      list-style: none;
      position: absolute;
      background: #fff;
      width: 100%;
      height: 1px;
      transform: translateY(-50%);
      transition: .3s;
    }
  
    .brick-1 {
      top: 20%;
    }
  
    .brick-2,
    .brick-3 {
      width: 50%;
      top: 50%;
    }
  
    .brick-1,
    .brick-2,
    .brick-4 {
      left: 0;
    }
  
    .brick-4 {
      top: 80%;
    }
  
    .brick-3 {
      right: 0;
    }
  
    &.active .brick-1 {
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    }
  
    &.active .brick-4 {
      top: 50%;
      transform: translateY(-50%) rotate(-45deg);
    }
  
    &.active .brick-2 {
      left: -50%;
      opacity: 0;
    }
  
    &.active .brick-3 {
      right: -50%;
      opacity: 0;
    }
  }
}
