/* Radio Group Styles */
.radio-card-group {
  display: grid;
  grid-auto-flow: column;
  column-gap: 16px;
  box-sizing: border-box;

  /* Radio Card */
  .radio-card {
    box-sizing: border-box;
    display: grid;
    cursor: pointer;
    grid-template-columns: 24px 204px;
    grid-template-rows: 1.5rem 1fr;
    border: 1px solid #083D62;
    width: max-content;
    padding: 1rem;
    border-radius: 4px;
  
    &--selected {
      border: 1px solid #146C98;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.1);
      transition: 0.1s;
    }
  
    &__title {
      font-weight: 600;
      font-size: 1rem;
      align-self: start;
      color: #8ED3E5;
    }
  
    &__content {
      margin-top: 4px;
      grid-column: 2 / 2;
      font-size: 0.8rem;
      align-self: start;
    }
  
    /* Radio */
    .radio {
      box-sizing: border-box;
      border-radius: 50%;
      border: 1px solid #1C88B4;
      height: 16px;
      width: 16px;
      margin-top: 4px;
      display: flex;
      align-self: start;
      align-items: center;
      justify-content: center;
      
      &--selected {
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: linear-gradient(315deg, #C61FB5 -6.25%, #2CABC7 100%);
      }
    }
  }
}
