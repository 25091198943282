.logo {
  box-sizing: border-box;
  border-radius: 50%;

  &-sm {
    height: 12px;
    width: 12px;
  }

  &-md {
    height: 16px;
    width: 16px;
  }

  &-lg {
    height: 24px;
    width: 24px;
  }
}