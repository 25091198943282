.base-tooltip {
  font-family: 'Montserrat', Helvetica, sans-serif;

  &.tooltip-inner {
    opacity: 1;
    max-width: 350px;
    padding: 0.5rem 1rem;
    color: #fff;
    text-align: center;
    border-radius: .25rem;
    background: #004671;
    box-shadow: 0px 0px 24px 4px rgba(0, 0, 0, 0.4);
    white-space: pre-line;
    text-align: left;
  }
}