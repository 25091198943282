.filters {
  width: 90vw;
  margin: auto;
  padding: 0;

  &__toggle {
    align-items: center;
    justify-content: flex-end;
    user-select: none;
  }

  &__claim-all {
    flex: 1;
  }

  &.lend {
    justify-content: flex-end;

    .filters__toggle {
      display: flex;
    }
  }
}

.category_filters {
  width: 90vw;
  margin: auto;
  padding: 0.5rem 0 1rem 0;
  display: flex;

  &.desktop {
    flex-direction: column;
  }

  &__title {
    margin-left: 4px;
    font-size: 0.8rem;
  }

  .staked-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .base-badge,
  .base-badge + &--badge {
    margin: 2px 4px;
    box-sizing: border-box;
    border: 2px solid transparent;
  }

  .active {
    border: 2px solid #5FBAD6;
  }

  &__tokens {
    margin-top: 8px;
  }

  .select-dropdown {
    margin-right: 16px;
  }
}

.harvest-list-item {
  transition: 0.1s;

  &:hover {
    background: rgba(255, 255, 255, 0.03);
    transition: 0.1s;
  }
}

.tulip-harvest-btn {
  background: #004671 !important;
  color: #FFF;
  border: none !important;
  transition: 0.3s;
  display: flex !important;
  align-items: center;
  border-radius: 8px !important;

  &:hover {
    background: #228FB0 !important;
    transition: 0.3s;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
  .filters {
    padding: 0;

    &.lend {
      .filters__toggle {
        display: block;
      }
    }
  }

  .category_filters {
    padding: 16px 0;
    flex-direction: column;

     &__dropdown {
      margin: 8px;
      display: flex;

      &.leverage-filter {
        margin-left: 0;
      }
    }
  }
}