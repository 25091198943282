.your-positions-table {
  width: 90vw;
  margin: auto;
  text-align: center;

  >.loader {
    margin: 3rem auto;
    transform: scale(2);
  }

  &__empty {
    margin-top: 2rem;
  }

  &__header {
    display: flex;
    border-bottom: thin solid #004671;
    padding: 32px;
    align-items: center;

    &-asset {
      flex: 1.3;
      text-align: left;
      display: flex;
      align-items: center;

      &.sortable {
        cursor: pointer;
        user-select: none;
      }
    }

    &-cell {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      &.sortable {
        cursor: pointer;
        user-select: none;

        &.disabled {
          cursor: not-allowed;
        }
      }

      &__info-btn {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 12px;
          margin-left: 0.3rem;
          cursor: pointer;
        }
      }

      &__sort-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 2px;
      }

      &__tulip-badge {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.7rem;
        background: rgba(255,255,255,0.1);
        border-radius: 10px;
        padding: 2px 6px;
        margin-left: 4px;

        img {
          height: 13px;
          width: 9px;
          margin-right: 0.3rem;
          cursor: pointer;
        }
      }

      &.yield-apr {
        flex: 2;
      }
    }

    .details {
      flex: 1.5;
    }

    &-refresh {
      flex: 0.5;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      // justify-self: flex-end;

      &__btn {
        cursor: pointer;
        transition: 0.1s;
        border-radius: 3px;
        height: 32px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background: rgba(255, 255, 255, 0.1);
          transition: 0.1s;
        }

        &.is-refreshing {
          img {
            animation-name: spin;
            animation-duration: 0.5s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
          }
        }


        .disabled & {
          cursor: not-allowed;
          opacity: 0.6;
        }
      }

      @keyframes spin {
        from {
            transform:rotate(0deg);
        }
        to {
            transform:rotate(360deg);
        }
    }
    }
  }

  &__row {
    border-bottom: thin solid #004671;
    transition: 0.3s;
    // cursor: pointer;

    &:hover {
      background: rgba(255, 255, 255, 0.03);
      transition: 0.1s;
    }

    &.is-expanded {
      // background: linear-gradient(315deg, #001422 0%, #004472 100%);
      // box-shadow: 0px 0px 24px 4px rgba(0, 0, 0, 0.4);

      background: #002A46;
      box-shadow: inset 0px -1px 0px #004671;
      transition: 0.3s;
    }

    &-item {
      display: flex;
      font-weight: 600;
      align-items: center;
      z-index: 10;
      padding: 32px;

      &__asset {
        flex: 1.3;
        // display: flex;
        text-align: left;

        &__logos {
          flex: 1;
          display: flex;
          align-items: center;

          &-item {
            height: 20px;
            width: 20px;
            border-radius: 50%;
          }
        }

        &__text {
          flex: 10;
          margin-left: 8px;

          &-name {
            display: flex;
            align-items: center;
          }

          &-tvl {
            font-weight: 100;
            font-size: 12px;
            // color: #5FBAD6;
            color: #FFF;
            
            .loader {
              display: inline-block;
              margin: 0 0.5rem;
            }
          }
        }
      }

      &__cell {
        flex: 1;

        &-usd {
          font-size: 12px;
          font-weight: 100;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .loader {
          display: inline-block;
        }

        &.has-error {
          color: #EA232F;
        }

        .add-collateral {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-bottom: 8px;
          align-self: stretch;

          .btn {
            background: #004671;
            border: none;
            // width: 100%;
            // margin-top: 24px;
            border-radius: 8px;
            padding: .625rem 1rem;
            justify-content: center;
            align-items: center;
            display: flex;
            font-size: 0.9rem;
            align-self: stretch;
            width: 100%;
  
            .loader {
              margin: 8px;
            }
  
            &.disabled {
              cursor: not-allowed;
            }
          }
        }

        .close-position {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          // margin-bottom: 8px;
          align-self: stretch;

          .btn {
            background: unset;
            border: thin solid #004671;
            // width: 100%;
            // margin-top: 24px;
            border-radius: 8px;
            padding: .625rem 1rem;
            justify-content: center;
            align-items: center;
            display: flex;
            font-size: 0.9rem;
            color: #FFF;
            align-self: stretch;
            width: 100%;
            box-sizing: border-box;
  
            .loader {
              margin: 8px;
            }
  
            &.disabled {
              cursor: not-allowed;
            }
          }
        }

        &.yield {
          font-weight: 100;
          font-size: 0.8rem;
          flex: 2;
          
          .yield-line {
            display: flex;
            padding: 2px 18px;
            align-items: center;

            &-name {
              flex: 1;
              text-align: left;
              justify-content: flex-start;
              color: #1C88B4;
            }
  
            &-value {
              text-align: right;
              justify-content: flex-end;
              margin-right: 4px;

              &.selector {
                margin-right: 0;
              }

              &.total {
                color: #8ED3E5;
              }
            }
          }

          .coin-selector {
            border: none;
            background: none !important;
            // color: #1C88B4;
            font-size: 0.8rem;
            padding: 0 4px;
    
            &:focus {
              border: none;
              box-shadow: unset !important;
            }
    
            &.active {
              color: #FFF;
            }

            &:hover {
              background: rgba(255, 255, 255, 0.06) !important;
            }
          }
        }

        input {
          background: transparent;
          // color: #5FBAD6;
          color: #FFF;
          border: thin solid #004671;
          padding: 21px 8px;
          border-radius: 8px;
          // margin: 0 5rem;
          width: 5rem;
          margin: auto;

          &:focus {
            background: transparent;
            // color: #5FBAD6;
            color: #FFF;
          }

          &.form-control:disabled {
            background: transparent;
            opacity: 0.6;
          }

          &::placeholder {
            color: #228FB0;
          }
        }

        // input[type=number]::-webkit-inner-spin-button, 
        // input[type=number]::-webkit-outer-spin-button { 
        //   // -webkit-appearance: none; 
        //   // -moz-appearance: none;
        //   // appearance: none;
        //       // margin: 0; 
        //   cursor: pointer;
        //   background: transparent;
        // }

        // input[type=number]::-webkit-outer-spin-button, 
        // input[type=number]::-webkit-inner-spin-button {
        //     -webkit-appearance: inner-spin-button;
        //     // background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAKUlEQVQYlWNgwAT/sYhhKPiPT+F/LJgEsHv37v+EMGkmkuImoh2NoQAANlcun/q4OoYAAAAASUVORK5CYII=) no-repeat center center;
        //     width: 1em;
        //     // border-left: 1px solid #BBB;
        //     opacity: .5; /* shows Spin Buttons per default (Chrome >= 39) */
        //     position: absolute;
        //     top: 0;
        //     right: 0;
        //     bottom: 0;
        //     cursor: pointer;
        //     background: transparent;

        //     -webkit-appearance: inner-spin-button !important;
        //     opacity: 1 !important;
        //     width: 50px;
        //     position: absolute;
        //     top: 0;
        //     right: 0;
        //     height: 100%;
        //     background-color: transparent no-repeat center center !important;
        //     border: 2.5px solid red !important;
        // }

        // input[type=number]::-webkit-outer-spin-button {
        //   background: transparent url('../icons/down-caret.svg') no-repeat center center;
        // }

        // input[type=number]::-webkit-inner-spin-button:hover,
        // input[type=number]::-webkit-inner-spin-button:active{
        //     // box-shadow: 0 0 2px #0CF;
        //     // opacity: .8;
        //     background-color: rgba(255, 255, 255, 0.1);
        // }

        .initial-apy {
          text-decoration: line-through;
          font-weight: 100;
          color: #8ED3E5;
          font-size: 0.9rem;
        }

        .adjusted-apy {

        }

        &.leverage {
          .customNumberInput {
            margin: 0 3rem;
          }
        }

        .rewards {
          color: #1CB434;
          display: inline-flex;
          font-size: 12px;
        }

        &.details {
          font-size: 12px;
          flex: 1.5;

          .detail-line {
            padding: 0 8px;
          }

          .detail-name {
            display: inline-flex;
            color: #1c88b4;
          }

          > div {
            display: flex;
            justify-content: space-between;
          }
        }

        &.actions {
          flex: 0.5;
        }

        .position-breakup {
          font-size: 0.8rem;
          border-radius: 8px;
          background: rgba(255, 255, 255, 0.03);
          margin-top: 8px;
          display: flex;
          flex-direction: column;
          margin: 8px;
          width: max-content;
          margin: 0 auto;

          &__item {
            display: flex;
            padding: 0.5rem;
            align-items: center;

            &-label {
              flex: 1;
              text-align: left;
              margin-right: 12px;
              display: flex;
              align-items: center;
            }

            &-value {
              text-align: right;
            }

            img {
              height: 12px;
              margin-right: 4px;
            }
          }
        }
      }

      &__toggle {
        flex: 0.2;
        user-select: none;
        transform: rotate(0deg);
        transition: transform 0.3s;
        justify-content: center;
        display: flex;
        align-items: center;

        .is-expanded & {
          transform: rotate(-180deg);
          transition: transform 0.3s;
        }

        &__btn {
          cursor: pointer;
          transition: 0.1s;
          border-radius: 3px;
          height: 32px;
          width: 32px;
          display: flex;
          align-items: center;
          justify-content: center;

          // &:hover {
          //   background: rgba(255, 255, 255, 0.1);
          //   transition: 0.1s;
          // }
        }
      }
    }

    &-expanded {
      display: flex;
      padding: 0 32px 32px;
      align-items: center;
      transition: margin-top 0.3s, opacity 0.6s, visibility 0.6s;
      box-sizing: border-box;
      z-index: 1;
      // margin-top: 0;

      &.is-hidden {
        // height: 0;
        // // opacity: 0;
        // visibility: hidden;
        // transition: 0.1s;
        // margin-top: -100%;
        opacity: 0;
        // display: none;
        transition: margin-top 0.3s, opacity 0.15s, visibility 0.15s;
        margin-top: -269px;
        visibility: hidden;

        .no-harvest & {
          margin-top: -241px;
        }
      }

      &__left-wrapper {
        flex: 2;
        padding-left: 40px;

        &__harvest {
          border-radius: 12px;
          border: thin solid #004671;
          padding: 16px;
          margin: 0 0 16px;
          
          &__text {
            display: flex;
            align-items: center;
            padding: 8px;
            font-size: 13px;

            &-label {
              justify-self: flex-start;
              text-align: left;
            }

            &-value {
              justify-self: flex-end;
              flex: 1;
              text-align: right;
            }
          }

          .btn {
            background: #004671;
            border: none;
            width: 100%;
            // margin-top: 24px;
            border-radius: 8px;
            padding: .625rem .75rem;
            justify-content: center;
            align-items: center;
            display: flex;

            .loader {
              margin: 8px;
            }

            &.disabled {
              cursor: not-allowed;
            }
          }
        }


        &__fees {
          // flex: 2;
          text-align: left;
          // color: #5FBAD6;
          color: #FFF;
          padding-left: 8px;
  
          &-header {
            font-size: 18px;
            font-weight: 600;
          }
  
          &-text {
            white-space: pre-line;
            font-size: 13px;
          }
        }
      }

      &__deposit, &__withdraw {
        flex: 3;
        border-radius: 12px;
        border: thin solid #004671;
        padding: 32px 16px;
        margin: 0 16px;
        align-self: stretch;
        display: flex;
        flex-direction: column;

        input {
          background: transparent;
          // color: #5FBAD6;
          color: #FFF;
          border: thin solid #004671;
          padding: 21px 8px;
          border-radius: 8px;

          &:focus {
            background: transparent;
            // color: #5FBAD6;
            color: #FFF;
          }

          &.form-control:disabled {
            background: transparent;
            opacity: 0.6;
          }

          &::placeholder {
            color: #228FB0;
          }
        }

        .btn {
          background: #004671;
          border: none;
          width: 100%;
          // margin-top: 24px;
          border-radius: 8px;
          padding: .625rem .75rem;
          justify-content: center;
          align-items: center;
          display: flex;

          .loader {
            margin: 8px;
          }

          &.disabled {
            cursor: not-allowed;
          }
        }

        .slider-container {
          margin: 8px 2px 12px 2px;
          display: flex;
          align-items: center;
          flex: 1;

          &__label {
            margin-left: 8px;
            // color: #5FBAD6;
            color: #FFF;
            font-size: 14px;
          }

          .MuiSlider-thumb.Mui-disabled {
            margin-top: -2px !important;
          }
        }
      }

      // &__withdraw {
      //   flex: 1;
      //   border-radius: 8px;
      //   border: thin solid #004671;
      //   padding: 32px 8px;
      //   margin: 0 8px;
      // }
    }
  }


  &__tooltip {
    font-family: 'Montserrat', Helvetica, sans-serif;

    &.tooltip-inner {
      opacity: 1;
      max-width: 350px;
      padding: 0.5rem 1rem;
      color: #fff;
      text-align: center;
      /* background-color: #000; */
      border-radius: .25rem;
      background: #004671;
      box-shadow: 0px 0px 24px 4px rgba(0, 0, 0, 0.4);
      white-space: pre-line;
      text-align: left;
    }
  }
}


// Mobile + Tablet - till tablet is separated out
@media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
  .your-positions-table {
    &__header {
      padding: 16px 8px;
    }

    &__row {
      &-item {
        padding: 16px 8px;
        align-items: center;
        height: 80px;

        &__asset {
          flex: 1.5;
          &__text {
            font-size: 0.8rem;

            &-tvl {
              font-size: 11px;
            }
          }

          &__logos {
            &-item {
              height: 16px;
              width: 16px;
            }
          }
        }

        .mobile-details {
          flex-direction: column;
          font-size: 9px;
          align-items: flex-start;
          font-weight: 100;
          padding: 4px;
          // border: 1px solid #146C98;
          border-radius: 8px;

          > div {
            display: flex;
            justify-content: space-between;
            width: 100%;
          }

          .rewards {
            font-size: 9px;
          }
        }
      }

      &-expanded {
        flex-direction: column-reverse;
        align-items: flex-start;
        padding: 0 16px 16px;

        &.is-hidden {
          margin-top: -369px;

          .no-harvest & {
            margin-top: -308px;
          }
        }

        .mobile-details-expanded {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-size: 12px;
          text-align: left;
          width: 100%;
          margin-bottom: 16px;
          padding: 0 4px;

          > div {
            display: flex;
            width: 100%;
            justify-content: space-between;
            line-height: 20px;
            font-size: 12px;
          }

          .tulip-harvest-btn {
            width: 100%;
            justify-content: center;
            margin-top: 16px;
            padding: 12px;
            font-size: 0.9rem;
          }

          .position-value-text {
            text-align: right;
          }

          .add-collateral {
            margin: 16px 0;
          }
        }

        &__left {
          &-wrapper {
            width: 100%;
            padding: 0 4px;
          }
        }
      }
    }
  }
}
