.select-dropdown {
  &__search {
    border: thin solid #004671 !important;
    border-radius: 8px !important;
    background: transparent !important;
    margin: 4px !important;
    color: #FFF !important;
    width: calc(100% - 1rem) !important;
    margin: 8px !important;
  }

  &__btn {
    // TODO: Move to secondary/tertiary type button
    background: transparent !important;
    border: 1px solid #146C98 !important;
    color: #FFF;
    transition: 0.3s;
    display: flex !important;
    border-radius: 8px !important;
    font-size: 0.9rem;
    box-sizing: border-box;
    height: 40px;
    align-items: center;
  }

  .dropdown-menu {
    .dropdown-item {
      font-size: 12px;
      padding: 0.5rem 3rem 0.5rem 2rem;

      img {
        height: 16px;
        width: 16px;
      }

      &:hover{
        background-color: #004671 !important;
      }

      &:focus {
        background-color: unset !important;
        color: #FFF !important;
      }

      &.is-active {
        border-left: 4px solid #5FBAD6;
        padding-left: calc(2rem - 4px);
        transition: border-left 0.1s;
        background: linear-gradient(315deg, #0C4D75 0%, #146C98 100%);
      }
    }
  }

  input {
    font-size: 12px;
  }
}