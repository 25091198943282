@font-face {
  font-family: 'MontserratAlternates';
  src: url('../fonts/MontserratAlternates-Medium.ttf');
  // src: url("CustomFont.woff") format("woff"),
  // url("CustomFont.otf") format("opentype"),
  // url("CustomFont.svg#filename") format("svg");
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Regular.ttf');
}

.tooltip.show {
  opacity: 1 !important;
}

// ::-webkit-scrollbar-corner {

// }

// ::-webkit-scrollbar {
//   background-color: black;
// }

// ::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
// }

// ::-webkit-scrollbar {
//     width: 0px;
// }

// ::-webkit-scrollbar-track-piece {
//     background-color: transparent;
//     -webkit-border-radius: 6px;
//     border-radius: 6px;
// }

::-webkit-scrollbar {
  background: transparent;
  width: 0.6rem;
}

::-webkit-scrollbar-track-piece {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.app {
  // text-align: center;
  // background-color: #131A35;
  // background: linear-gradient(315deg, #001426 0%, #042E4F 100%);
  min-height: 100vh;
  color: white;
  font-family: 'Montserrat', Helvetica, sans-serif;
  // padding-bottom: 32px;
  display: flex;
  flex-direction: column;

  &-header {
    // background-color: #131A35;
    background: #042E4F;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    text-align: center;
    // padding: 32px 0;
    width: 100vw;
    padding: 0 1vw;
    margin: auto;
    border-bottom: thin solid #004671;
    position: fixed;
    z-index: 1000;

    &.desktop {
      padding: 0 5vw;
    }

    > div {
      padding: 8px;
    }

    &__left {
      flex: 2;
      display: flex;
      align-items: center;

      &__logo {
        font-weight: 600;
        // flex: 1;
        text-align: left;
        font-family: 'MontserratAlternates';
        letter-spacing: 2px;
        font-size: 24px;
        display: flex;
        align-items: center;
        margin-right: 12px;
        cursor: pointer;
        // border-right: thin solid #004671;
  
        &-img {
          height: 24px;
          margin-right: 8px;
        }

        .base-badge {
          margin: unset;
          margin-left: 4px;
          font-family: 'Montserrat';
          // font-weight: 100;
        }
      }

      &__routes {
        font-size: 16px;
        // font-weight: 600;
        // letter-spacing: -1px;
        display: flex;
        align-items: center;
        flex: 1;
        margin-left: 4px;

        &__item {
          margin: 0 8px;
          color: #1C88B4;
          transition: color 0.2s;
          // font-size: 0.9rem;

          &.active {
            color: #FFF;

            &:hover {
              color: #FFF;
            }
          }

          &:hover {
            text-decoration: unset;
            // color: #31A1C8;
            color: #5FBAD6;
            transition: color 0.2s;
          }
        }
      }
    }

    &__meta {
      font-size: 14px;
      flex: 1;
      text-align: right;

      &-item {
        margin-right: 16px;
        // color: #5FBAD6;
        color: #FFF;

        &__label {

        }

        &__value {
          font-weight: 600;
        }
      }
    }

    &__menu {
      position: absolute;
      width: 100vw;
      top: 57px;
      display: flex;
      justify-content: center;
      background: var(--brand-85);
      box-shadow: 0px 10px 30px 4px rgba(0, 0, 0, 0.4);
      margin: 0;
      padding: 0 !important;
      z-index: 3;
      opacity: 0;
      transition: opacity 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);

      ul {
        width: 100%;
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: none;

        li {
          padding: 12px;
          font-size: 16px;

          &:hover {
            // background: linear-gradient(315deg, #042E4F, 20%, #146C98 50%);
            background: var(--bg-hover);
          }
        }
      }

      &.is-active {
        opacity: 1;
        transition: opacity 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);

        ul {
          display: block;
        }
      }
    }

    .btn {
      /* theme/base */
      // background: linear-gradient(315deg, #065B95 -6.25%, #07A6C9 100%);

      background: #004671 !important;
      // background: red;
      color: #FFF;
      border: none !important;
      transition: 0.3s;
      display: flex !important;
      border-radius: 8px !important;
      font-size: 0.9rem;
      box-sizing: border-box;
      height: 40px;
      align-items: center;
      // background-size: 100% 90px;
      // background-position: 0 -30px;

      &:hover {
        // opacity: 0.8;
        // background: linear-gradient(315deg, #07A6C9 -6.25%, #065B95 100%);
        // background: blue;
        // background-position: -10px;
        // background-position: 0 0;
        background: #228FB0 !important;
        transition: 0.3s;
      }

      &.rpc-selector {
        background: none !important;
        border: 1px solid #004671 !important;

        &:hover {
          border: 1px solid #228FB0 !important;
        }

        img:nth-child(1) {
          margin-right: 8px;
        }
      }

      &.route-selector {
        border: none;
        background: none !important;
        color: #1C88B4;
        font-size: 1rem;

        &:focus {
          border: none;
          box-shadow: unset !important;
        }

        &.active {
          color: #FFF;
        }
      }
    }

    .loader {
      display: inline-block;
      margin: 0 1rem;
    }
  }

  &-body {
    flex: 1;
    padding-top: 16px;
    margin-top: 60px;

    &__meta {
      display: flex;
      margin: 8px;

      &-filter {
        flex: 1;
        padding: 0px 32px;
      }

      &-actions {
        flex: 2;
        text-align: right;
        padding: 8px 32px;
      }
    }

    .banner {
      width: 90vw;
      text-align: center;
      margin: auto;
      padding: 0.5rem;
      background: rgba(255,255,255,0.1);
      border-radius: 0.5rem;
      font-weight: 600;
    }

    .banner-shift {
      margin-top: 72px;
    }

    @media only screen and (max-width: 1226px) {
      .banner-shift {
        margin-top: calc(1vh + 72px);
      }
    }

    .banner-alt {
      // margin-top: 1rem;

      &__link {
        cursor: pointer;
        color: #5FBAD6;
        margin-left: 0.3rem;
      }
    }

    &__modal {
      font-family: 'Montserrat', Helvetica, sans-serif;

      .modal-content {
        background: #002A46;
        color: #FFF;
        border-radius: 0.5rem;

        .modal-header {
          border-bottom: thin solid #004671;

          .close {
            color: #FFF;
            text-shadow: unset;
            transition: 0.2s;

            &:hover {
              transition: 0.2s;
              // background-color: rgba(255, 255, 255, 0.1);
            }
          }
        }

        .modal-body {
          padding-top: 1rem;
        }

        .modal-footer {
          border-top: thin solid #004671;

          .modal__success-btn {
            border: none;
          }

          .modal__cancel-btn {
            background: #004671;
            border: none;

            &:hover {
              background-color: #0069d9;
            }
          }
        }
      }
    }
  }

  &-footer {
    // background: #042E4F;
    background: #02203B;
    margin-top: 32px;
    display: flex;
    align-items: center;
    padding: 16px 32px;
    border-top: thin solid #004671;
    box-sizing: border-box;

    &__left {
      flex: 1;
      display: flex;
      align-items: center;

      &-link {
        margin: 8px;
      }
    }

    &__right {
      flex: 1;
      display: flex;
      align-items: center;
      margin-left: auto;
      justify-content: flex-end;

      &-icon {
        margin: 8px;
        cursor: pointer;

        img {
          height: 32px;
          width: 32px;
          transition: 0.2s;
        }

        &:hover {
          img {
            filter: invert(1) sepia(1) saturate(5) hue-rotate(175deg);
            transition: 0.2s;
          }
        }
      }
    }
  }
}

input[type=checkbox] {
  transform: scale(1.5);
}

.farm-table {
  margin-top: 32px;
  background: none !important;
  border-color: #004671 !important;
  border-bottom: thin solid #004671 !important;
}

.farm-table td {
  border-color: #004671 !important;
}

.farm-table th {
  border-bottom: thin solid !important;
  border-top: none !important;
  border-color: #004671 !important;
}

.dropdown-menu {
  // background: linear-gradient(315deg, #001422 0%, #004472 100%);
  // background: #004671 !important;
  background: #002A46 !important;
  box-shadow: 0px 0px 24px 4px rgba(0, 0, 0, 0.4);
  border: none !important;
  top: 4px !important;
  left: 2px !important;
  
  .dropdown-item {
    color: #FFF;
    font-size: 16px;
    transition: 0.1s;
    padding: 0.5rem 1.5rem;
    align-items: center;
    display: flex;

    &:hover {
      background: #004671 !important;
      color: #FFF !important;
      transition: 0.1s;
    }

    img {
      height: 28px;
      width: 28px;
      border-radius: 50%;
      margin-right: 16px;
    }
  }
}

.dropdown-toggle::after {
  align-self: center;
}

.separator {
  height: 28px;
  width: 1px;
  background-color: #004671;
}

.page-heading {
  font-size: 1.4rem;
  font-weight: 600;

  display: flex;
  align-items: center;
  width: 90vw;
  margin: auto;
  padding: 1rem 0;

  &__title {
    flex: 1;
    margin: 0 4px;
  }
}

// Global level slide in and slide out animation for row items
// used with the CSSTransitionGroup
.slide-in-enter {
  opacity: 0;
  transition: margin-top 0.3s, opacity 0.15s, visibility 0.15s;
  margin-top: -236px;
  visibility: hidden;
}

.slide-in-enter.slide-in-enter-active {
  visibility: visible;
  transition: margin-top 0.3s, opacity 0.6s, visibility 0.6s;
}

.slide-in-leave {
  margin-top: -236px;
  visibility: hidden;

  transition: margin-top 0.3s, opacity 0.09s, visibility 0.09s;
}

.slide-in-leave.slide-in-leave-active {
  margin-top: -236px;
  visibility: hidden;

  transition: margin-top 0.3s, opacity 0.09s, visibility 0.09s;
}

// Mobile + Tablet - till tablet is separated out
@media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
  .app {
    &-header {
      &__left {
        padding-left: 16px;

        &__routes {
          &__item {
            margin: 0;
            display: block;
            width: 100%;
            height: 100%;
          }
        }

        &__logo {
          font-size: 20px;

          &-img {
            height: 20px;
            margin-left: 8px;
          }

          .base-badge {
            &.badge-secondary {
              font-size: 8px;
            }
          }
        }
      }
    }

    &-footer {
      padding: 16px;

      &__left {

      }

      &__right {
        &-icon {
          img {
            height: 24px;
            width: 24px;
          }
        }
      }
    }
  }
}