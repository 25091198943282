.lend-table {
  width: 90vw;
  margin: auto;
  text-align: center;

  >.loader {
    margin: 3rem auto;
    transform: scale(2);
  }

  &__empty {
    margin-top: 2rem;
  }

  &__header {
    display: flex;
    border-bottom: thin solid #004671;
    padding: 16px;
    align-items: center;

    &-asset {
      flex: 2;
      text-align: left;
      display: flex;
      align-items: center;

      &.sortable {
        cursor: pointer;
        user-select: none;
      }
    }

    &-cell {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      &.sortable {
        cursor: pointer;
        user-select: none;

        &.disabled {
          cursor: not-allowed;
        }
      }

      &__info-btn {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 12px;
          margin-left: 0.3rem;
          cursor: pointer;
        }
      }

      &__sort-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 2px;
      }

      &__tulip-badge {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.7rem;
        background: rgba(255,255,255,0.1);
        border-radius: 10px;
        padding: 2px 6px;
        margin-left: 4px;

        img {
          height: 13px;
          width: 9px;
          margin-right: 0.3rem;
          cursor: pointer;
        }
      }
    }

    &-refresh {
      flex: 0.2;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;

      &__btn {
        cursor: pointer;
        transition: 0.1s;
        border-radius: 3px;
        height: 32px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background: rgba(255, 255, 255, 0.1);
          transition: 0.1s;
        }

        &.is-refreshing {
          img {
            animation-name: spin;
            animation-duration: 0.5s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
          }
        }


        .disabled & {
          cursor: not-allowed;
          opacity: 0.6;
        }
      }

      @keyframes spin {
        from {
            transform:rotate(0deg);
        }
        to {
            transform:rotate(360deg);
        }
    }
    }
  }

  &__row {
    border-bottom: thin solid #004671;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      background: rgba(255, 255, 255, 0.03);
      transition: 0.1s;
    }

    &.is-expanded {
      // background: linear-gradient(315deg, #001422 0%, #004472 100%);
      // box-shadow: 0px 0px 24px 4px rgba(0, 0, 0, 0.4);

      background: #002A46;
      box-shadow: inset 0px -1px 0px #004671;
      transition: 0.3s;
    }

    &-item {
      display: flex;
      font-weight: 600;
      align-items: center;
      z-index: 10;
      padding: 16px;

      &__asset {
        flex: 2;
        display: flex;
        text-align: left;

        &__logos {
          flex: 1;
          display: flex;
          align-items: center;

          &-item {
            height: 20px;
            width: 20px;
            border-radius: 50%;
          }
        }

        &__text {
          flex: 10;
          margin-left: 8px;

          &-name {
            display: flex;
            align-items: center;
          }

          &-tvl {
            font-weight: 100;
            font-size: 12px;
            // color: #5FBAD6;
            color: #FFF;
            
            .loader {
              display: inline-block;
              margin: 0 0.5rem;
            }
          }
        }
      }

      &__cell {
        flex: 1;

        &-usd {
          font-size: 12px;
          font-weight: 100;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .loader {
          display: inline-block;
        }

        &.has-error {
          color: #EA232F;
        }
      }

      &__toggle {
        flex: 0.2;
        user-select: none;
        transform: rotate(0deg);
        transition: transform 0.3s;
        justify-content: center;
        display: flex;
        align-items: center;

        .is-expanded & {
          transform: rotate(-180deg);
          transition: transform 0.3s;
        }

        &__btn {
          cursor: pointer;
          transition: 0.1s;
          border-radius: 3px;
          height: 32px;
          width: 32px;
          display: flex;
          align-items: center;
          justify-content: center;

          // &:hover {
          //   background: rgba(255, 255, 255, 0.1);
          //   transition: 0.1s;
          // }
        }
      }
    }

    &-expanded {
      display: flex;
      padding: 0 32px 32px;
      align-items: center;
      transition: margin-top 0.3s, opacity 0.6s, visibility 0.6s;
      box-sizing: border-box;
      z-index: 1;
      // margin-top: 0;

      &.is-hidden {
        // height: 0;
        // // opacity: 0;
        // visibility: hidden;
        // transition: 0.1s;
        // margin-top: -100%;
        opacity: 0;
        // display: none;
        transition: margin-top 0.3s, opacity 0.15s, visibility 0.15s;
        margin-top: -236px;
        visibility: hidden;

        .no-harvest & {
          margin-top: -241px;
        }
      }

      &__left-wrapper {
        flex: 2;
        padding-left: 24px;

        &__harvest {
          border-radius: 12px;
          border: thin solid #004671;
          padding: 16px;
          margin: 0 0 16px;
          
          &__text {
            display: flex;
            align-items: center;
            padding: 8px;
            font-size: 13px;

            &-label {
              justify-self: flex-start;
              text-align: left;
            }

            &-value {
              justify-self: flex-end;
              flex: 1;
              text-align: right;
            }
          }

          .btn {
            background: #004671;
            border: none;
            width: 100%;
            // margin-top: 24px;
            border-radius: 8px;
            padding: .625rem .75rem;
            justify-content: center;
            align-items: center;
            display: flex;

            .loader {
              margin: 8px;
            }

            &.disabled {
              cursor: not-allowed;
            }
          }
        }


        &__fees {
          // flex: 2;
          text-align: left;
          // color: #5FBAD6;
          color: #FFF;
          padding-left: 8px;
  
          &-header {
            font-size: 18px;
            font-weight: 600;
          }
  
          &-text {
            white-space: pre-line;
            font-size: 13px;
          }
        }
      }

      &__deposit, &__withdraw {
        flex: 3;
        border-radius: 12px;
        border: thin solid #004671;
        padding: 32px 16px;
        margin: 0 16px;
        align-self: stretch;
        display: flex;
        flex-direction: column;

        input {
          background: transparent;
          // color: #5FBAD6;
          color: #FFF;

          &:focus {
            background: transparent;
            // color: #5FBAD6;
            color: #FFF;
          }

          &.form-control:disabled {
            background: transparent;
            opacity: 0.6;
          }
        }

        input {
          border: thin solid #004671;
          padding: 21px 8px;
          border-radius: 8px;

          &::placeholder {
            color: #228FB0;
          }
        }

        .btn {
          background: #004671;
          border: none;
          width: 100%;
          // margin-top: 24px;
          border-radius: 8px;
          padding: .625rem .75rem;
          justify-content: center;
          align-items: center;
          display: flex;

          .loader {
            margin: 8px;
          }

          &.disabled {
            cursor: not-allowed;
          }
        }

        .slider-container {
          margin: 8px 2px 12px 2px;
          display: flex;
          align-items: center;
          flex: 1;

          &__label {
            margin-left: 8px;
            // color: #5FBAD6;
            color: #FFF;
            font-size: 14px;
          }

          .MuiSlider-thumb.Mui-disabled {
            margin-top: -2px !important;
          }
        }
      }

      // &__withdraw {
      //   flex: 1;
      //   border-radius: 8px;
      //   border: thin solid #004671;
      //   padding: 32px 8px;
      //   margin: 0 8px;
      // }
    }
  }


  &__tooltip {
    font-family: 'Montserrat', Helvetica, sans-serif;

    &.tooltip-inner {
      opacity: 1;
      max-width: 350px;
      padding: 0.5rem 1rem;
      color: #fff;
      text-align: center;
      /* background-color: #000; */
      border-radius: .25rem;
      background: #004671;
      box-shadow: 0px 0px 24px 4px rgba(0, 0, 0, 0.4);
      white-space: pre-line;
      text-align: left;
    }
  }
  
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    -moz-appearance: none;
    appearance: none;
        // margin: 0; 
    // cursor: pointer;
    // background: none;
  }
}

// Mobile + Tablet - till tablet is separated out
@media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
  .lend-table {
    &__header {
      padding: 16px 8px;

      &-sort-by {
        margin-left: auto;

        button {
          border: none !important;
          padding-left: 1rem !important;
          font-size: 16px;
        }
      }
    }

    &__row {
      // Ref: https://www.bram.us/2020/03/01/prevent-content-from-being-hidden-underneath-a-fixed-header-by-using-scroll-margin-top/
      scroll-margin-top: 60px;
      scroll-snap-margin-top: 60px;

      &-item {
        padding: 16px 8px;
        height: 80px;

        &__asset {
          flex: 1.5;
          &__text {
            font-size: 0.8rem;

            &-tvl {
              font-size: 11px;
            }
          }

          &__logos {
            flex: 0;

            &-item {
              height: 16px;
              width: 16px;
            }
          }
        }

        .mobile-details {
          flex-direction: column;
          font-size: 9px;
          align-items: flex-start;
          font-weight: 100;
          padding: 4px;
          // border: 1px solid #146C98;
          border-radius: 8px;

          > div {
            display: flex;
            justify-content: space-between;
            width: 100%;
          }
        }
      }

      &-expanded {
        flex-direction: column-reverse;
        align-items: flex-start;
        padding: 0 16px 16px;

        &.is-hidden {
          margin-top: -544px;

          .no-harvest & {
            margin-top: -619px;
          }
        }

        &__deposit, &__withdraw {
          margin: 8px 0;
          padding: 16px;

          .form-control {
            font-size: 12px;
          }
        }

        .mobile-details-expanded {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-size: 12px;
          text-align: left;
          width: 100%;
          margin-bottom: 16px;
          padding: 0 4px;

          > div {
            display: flex;
            width: 100%;
            justify-content: space-between;
            line-height: 18px;
          }
        }

        &__left {
          &-wrapper {
            width: 100%;
            padding: 0 4px;

            &__fees {
              padding-left: unset;
              padding-top: 8px;

              &-header {
                font-size: 14px;
              }

              &-text {
                font-size: 11px;
              }
            }
          }
        }
      }
    }

    &__fee-text {
      > div {
        justify-content: space-between;
      }
    }
  }
}