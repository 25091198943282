$warning-color: #FFCF3F !default;
$warning-bg-color: #FFF9E0 !default;

$error-color: #F37264 !default;
$error-bg-color: #FFEBE7 !default;

:root {
  // Content colors
  --content-primary: #F8FEFF;
  --content-secondary: #8ED3E5;
  --content-tertiary: #1C88B4;
  --content-disabled: #0C4D75;
  --content-success: #1CB434;
  --content-warning: #E5DD18;
  --content-error: #EA232F;

  // Background colors
  --bg-error: #FFEBE7;
  --bg-secondary: #042E4F;
  --bg-tertiary: #146C98;
  --bg-hover: #083D62;

  // Gradients
  --theme-base: linear-gradient(315deg, #C61FB5 -6.25%, #2CABC7 100%);
  --theme-base-secondary: linear-gradient(315deg, #0C4D75 0%, #146C98 100%);
  --theme-red: linear-gradient(0deg, #690707 2.08%, #C90741 100%);
  --bg-primary: linear-gradient(315deg, #001426 0%, #042E4F 100%);

  // Border
  --border-default: #083D62;

  // Brand colors
  --brand-00: #F8FEFF;
  --brand-05: #DCF5F9;
  --brand-10: #C1EBF4;
  --brand-15: #A7DFEC;
  --brand-20: #8ED3E5;
  --brand-30: #5FBAD6;
  --brand-40: #31A1C8;
  --brand-50: #1C88B4;
  --brand-60: #146C98;
  --brand-70: #0C4D75;
  --brand-75: #083D62;
  --brand-80: #042E4F;
  --brand-85: #02203B;
  --brand-90: #001426;
}