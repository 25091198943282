body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  // overflow-y: overlay;
  // height: 100vh;
  // width: 100vw;
  background: linear-gradient(315deg, #001426 0%, #042E4F 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: unset;
  text-decoration: unset;

  &:hover {
    color: unset !important;
    text-decoration: unset !important;
  }

  &.link {
    color: #5FBAD6 !important;

    &:hover {
      text-decoration: underline !important;
    }
  }
}

@import './variables';
@import './App.scss';
@import './vaults-table';
@import './toast';
@import './loader';
@import './filters';
@import './lend-table';
@import './base-tooltip';
@import './leverage-farming-table';
@import './leverage-farming-modal';
@import './close-position-modal';
@import './repair-issues-modal';
@import './custom-number-input';
@import './your-positions-table';
@import './badge';
@import './logo';
@import './infobar';
@import './radio-card';
@import './menu-icon';
@import './select';
@import '../components';
// @import './static';