.repair-issues-modal {
  .btn {
    > .loader {
      margin: 8px;
    }
  }
}

// Mobile + Tablet - till tablet is separated out
@media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
  .repair-issues-modal {
    font-size: 0.8rem;
    button {
      font-size: 0.8rem;
    }
  }
}