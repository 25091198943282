.customNumberInput {
  display: flex;
  align-items: center;
  border: thin solid #004671;
  padding: 0 8px;
  border-radius: 8px;
  box-sizing: border-box;
  user-select: none;
  // height: 100%;
  // width: 100%;

  &-icon {
    height: 20px;
    width: 20px;
  }

  &-input {
    border: none !important;

    &:focus {
      border: none;
      box-shadow: none !important;
    }

    &:active {
      border: none;
      box-shadow: none;
    }
  }

  &-arrows {
    &__up {
      transform: rotate(180deg);
    }

    &__down, &__up {
      cursor: pointer;
      height: 16px;
      width: 16px;
      display: flex;
      align-items: center;
      transition: 0.1s;
      border-radius: 3px;

      img {
        transition: filter 0.2s;
      }

      &:hover {
        // background: rgba(255, 255, 255, 0.1);
        img {
          filter: saturate(0) brightness(2);
          transition: filter 0.2s;
        }
      }
    }
  }

  .separator {
    margin: 0 8px;
  }

  &-rightBtn {
    cursor: pointer;
    color: #1C88B4;
    font-size: 0.8rem;
    transition: color 0.1s;

    &:hover {
      color: #FFF;
      transition: color 0.1s;
    }
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    -moz-appearance: none;
    appearance: none;
  }
}